import { createSelector } from "@reduxjs/toolkit";
import {
  getTotalPriceText,
  twoDecimalFormatter,
  emailRegex,
  phoneRegex,
  getRewardsString,
  roundToTwoDecimals,
  getAgentErrorTitle,
  getAgentErrorSubtitle,
  CurrencyFormatters,
  getSubtotalWithAncillariesLegacy,
  IHotelPriceLineItem,
  IconName,
  truncateToTwoDecimals,
  getCheckoutCreditBreakdown,
  TEST_CARD_LAST_FOURS,
} from "halifax";
import {
  HotelBookErrorType,
  FiatPrice,
  RewardsPrice,
  CallState,
  PaymentAmountEnum,
  PaymentSplitRequest,
  PaymentSplitRequestEnum,
  HotelQuoteScheduleRequest,
  PaymentErrorEnum,
  PaymentVerifyResultEnum,
  ModalAlertProperties,
  MODAL_ALERT,
  NO_AVAILABILITY_HOTELS,
  ModalCategoryType,
  ModalScreens,
  RewardsAccountMinimumRequirementState,
  getRewardsAccountMinimumRequirementState,
  PaymentType,
  UserCardPaymentType,
  TypeOfPaymentEnum,
  SplitPaymentType,
  RewardsPaymentType,
  ErrorTitles,
  AvailableProductEnum,
  MerchantOfRecord,
  PaymentSplitRequestV2,
  PaymentV2,
  TravelWalletOfferPayment,
  TravelWalletCreditPayment,
  PaymentV2Enum,
  Rewards,
  UserCard,
  Prices,
  CompleteBuyPremierCollectionProperties,
  CancellationPolicyEnum,
  ITrackingProperties,
  ReviewDetailsHotelCheckoutProperties,
  PriceQuotePremierCollectionProperties,
  HotelPriceQuoteScheduleRequestV2,
  AncillaryKindEnum,
  CompleteBuyLifestyleCollectionProperties,
  LodgingCollectionEnum,
  ProductClassEnum,
  RewardsAccount,
  CustomerAccountRole,
  DatelessSearchProperties,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  getSearchedNightCount,
  getSelectedLodgingIndex,
} from "../../../availability/reducer";
import {
  getAddedAncillariesPricing,
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopChosenRoomInfo,
  getPremierCollectionShopSelectedAvailability,
  getViewedPremierCollectionDetailsProperties,
} from "../../../shop/reducer/selectors";
import {
  getAgentEmail,
  getAllowRewardsWithPolicy,
  getRewardsAccounts,
  getRewardsAccountWithLargestValue,
} from "../../../rewards/reducer";
import * as textConstants from "./textConstants";
import {
  getFromDate,
  getIsFromHotelDatelessSearch,
  getNightCount,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getPetsCount,
} from "../../../search/reducer";
import dayjs from "dayjs";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  ErrorCode,
  Payment,
  PaymentError,
  PaymentOpaqueValue,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import {
  DO_NOT_APPLY_OPTION_KEY,
  selectedCfarIdSelector,
} from "../../../ancillary/reducer";
import { isCaponeTenant, isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const getUserPassengers = (state: IStoreState) =>
  state.premierCollectionBook.userPassengers;

export const getUserSelectedTravelerId = (state: IStoreState) => {
  return state.premierCollectionBook.userSelectedTravelerId;
};

export const getUserSelectedTraveler = createSelector(
  getUserSelectedTravelerId,
  getUserPassengers,
  (userSelectedTravelerId, travelers) => {
    return travelers.find((t) => t.id === userSelectedTravelerId);
  }
);

export const getUserPassengerCallState = (state: IStoreState) =>
  state.premierCollectionBook.userPassengerCallState;

export const getConfirmationEmail = (state: IStoreState) =>
  state.premierCollectionBook.confirmationEmailAddress;

export const getConfirmationPhoneNumber = (state: IStoreState) =>
  state.premierCollectionBook.confirmationPhoneNumber;

export const getPaymentMethods = (state: IStoreState) =>
  state.premierCollectionBook.paymentMethods;

export const getPaymentMethod = (state: IStoreState) =>
  state.premierCollectionBook.paymentMethod;

export const getSelectedPaymentMethodId = (state: IStoreState) =>
  state.premierCollectionBook.selectedPaymentMethodId;

export const getListPaymentMethodsCallState = (state: IStoreState) =>
  state.premierCollectionBook.listPaymentMethodCallState;

export const getDeletePaymentMethodCallState = (state: IStoreState) =>
  state.premierCollectionBook.deletePaymentMethodCallState;

export const getFetchPaymentMethodCallState = (state: IStoreState) =>
  state.premierCollectionBook.fetchPaymentMethodCallState;

export const getVerifyPaymentMethodCallState = (state: IStoreState) =>
  state.premierCollectionBook.verifyPaymentMethodCallState;

export const getSession = (state: IStoreState) =>
  state.premierCollectionBook.session;

export const getPriceQuote = (state: IStoreState) =>
  state.premierCollectionBook.priceQuote;

export const getPricingWithAncillaries = (state: IStoreState) =>
  state.premierCollectionBook.pricingWithAncillaries;

export const getPriceQuoteRequest = (state: IStoreState) =>
  state.premierCollectionBook.priceQuoteRequest;

// Corporate Travel
export const getSubmitForApprovalCallState = (state: IStoreState) =>
  state.premierCollectionBook.submitForApprovalCallState;

export const getSubmitForApprovalError = (state: IStoreState) =>
  state.premierCollectionBook.submitForApprovalError;

export const getTripPurpose = (state: IStoreState) =>
  state.premierCollectionBook.tripPurpose;

// For Hotel Ancillaries, the priceQuote is only the hotelQuote and doesn't include
// the ancillary costs. The ancillary costs are included in the combinedPricing that
// comes from the BE and is stored in the priceQuoteWithAncillaries. If
// priceQuoteWithAncillaries is available, we want to use that for the total amount due.
export const getPriceQuotePricing = createSelector(
  getPriceQuote,
  getPricingWithAncillaries,
  (priceQuote, pricingWithAncillaries) => {
    if (pricingWithAncillaries) {
      return pricingWithAncillaries;
    } else {
      return priceQuote?.pricing;
    }
  }
);

export const getPriceQuoteErrors = (state: IStoreState) =>
  state.premierCollectionBook.priceQuoteErrors;

export const getSchedulePriceQuoteError = (state: IStoreState) =>
  state.premierCollectionBook.schedulePriceQuoteError;

export const getScheduleBookError = (state: IStoreState) =>
  state.premierCollectionBook.scheduleBookError;

export const getPollPriceQuoteCallState = (state: IStoreState) =>
  state.premierCollectionBook.pollPriceQuoteCallState;

export const getSchedulePriceQuoteCallState = (state: IStoreState) =>
  state.premierCollectionBook.schedulePriceQuoteCallState;

export const getConfirmationDetails = (state: IStoreState) =>
  state.premierCollectionBook.confirmationDetails;

export const getConfirmationDetailsError = (state: IStoreState) =>
  state.premierCollectionBook.confirmationDetailsErrors;

export const getConfirmationDetailsCallState = (state: IStoreState) =>
  state.premierCollectionBook.confirmationDetailsCallState;

export const getOffers = (state: IStoreState) =>
  state.premierCollectionBook.offers;

export const getOfferToApply = (state: IStoreState) =>
  state.premierCollectionBook.offerToApply;

export const getCredit = (state: IStoreState) => {
  const credit = state.premierCollectionBook.credit;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getCreditToApply = (state: IStoreState) => {
  const credit = state.premierCollectionBook.creditToApply;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getTravelWalletItemsToApply = createSelector(
  getOfferToApply,
  getCreditToApply,
  (offerToApply, creditToApply) => ({
    walletItem: offerToApply || creditToApply,
    offerToApply,
    creditToApply,
  })
);

export const getFetchApplicableTravelWalletItemsCallState = (
  state: IStoreState
) => state.premierCollectionBook.fetchApplicableTravelWalletItemsCallState;

export const getBestOfferOverall = (state: IStoreState) =>
  state.premierCollectionBook.bestOfferOverall;

export const getProductPricingEstimate = createSelector(
  getPremierCollectionShopChosenProduct,
  (product) => {
    return product?.total || null;
  }
);

export const getProductPricingEstimateWithAllFees = createSelector(
  getPremierCollectionShopChosenProduct,
  (product) => {
    return product?.tripTotal || null;
  }
);

export const getPricingEstimateTotal = createSelector(
  getProductPricingEstimate,
  getAddedAncillariesPricing,
  (productEstimate, addedAncillaryPricing) => {
    return productEstimate
      ? getSubtotalWithAncillariesLegacy(productEstimate, addedAncillaryPricing)
      : null;
  }
);

export const getPricingEstimateTotalWithAllFees = createSelector(
  getProductPricingEstimateWithAllFees,
  getAddedAncillariesPricing,
  (productEstimate, addedAncillaryPricing) => {
    return productEstimate
      ? getSubtotalWithAncillariesLegacy(productEstimate, addedAncillaryPricing)
      : null;
  }
);

export const getPriceDifferenceAcknowledged = (state: IStoreState) =>
  state.premierCollectionBook.priceDifferenceAcknowledged;

export const getPriceDifference = createSelector(
  getPriceQuote,
  getPricingEstimateTotal,
  getPriceDifferenceAcknowledged,
  getPriceQuotePricing,
  (
    priceQuote,
    estimate,
    acknowledged,
    priceQuotePricing
  ): {
    hasDifference: boolean;
    isIncrease: boolean;
    predictedTotal: number;
    priceQuoteTotal: number;
    amount?: string;
  } => {
    if (!priceQuote || !priceQuotePricing || !estimate || acknowledged) {
      return {
        hasDifference: false,
        isIncrease: false,
        predictedTotal: 0,
        priceQuoteTotal: 0,
      };
    }

    const predictedTotal = estimate.fiat.value;
    const priceQuoteTotal = priceQuotePricing?.payNowTotal.fiat.value;

    return {
      // If the difference is within a dollar we don't show the error modal
      hasDifference: Math.abs(priceQuoteTotal - predictedTotal) >= 1,
      isIncrease: priceQuoteTotal > predictedTotal,
      amount: Math.abs(priceQuoteTotal - predictedTotal)
        .toFixed(0)
        .toString(),
      predictedTotal,
      priceQuoteTotal,
    };
  }
);

export const getRewardsConversionFailed = (state: IStoreState) =>
  state.premierCollectionBook.rewardsConversionFailed;

export const getHasError = createSelector(
  getUserPassengerCallState,
  getPriceDifference,
  getPriceDifferenceAcknowledged,
  getPollPriceQuoteCallState,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsCallState,
  getRewardsConversionFailed,
  getVerifyPaymentMethodCallState,
  (
    passengerCallState,
    priceDifference,
    priceDifferenceAcknowledged,
    priceQuoteCallState,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsCallState,
    rewardsConversionFailed,
    verifyPaymentMethodCallState
  ) =>
    passengerCallState === CallState.Failed ||
    (priceDifference.hasDifference && !priceDifferenceAcknowledged) ||
    !!rewardsConversionFailed ||
    !!scheduleBookError ||
    !!schedulePriceQuoteError ||
    verifyPaymentMethodCallState === CallState.Failed ||
    confirmationDetailsCallState === CallState.Failed ||
    priceQuoteCallState === CallState.Failed
);

const getErrorMessage = (error: PurchaseError): ErrorTitles => {
  const { code = "" } = error as ErrorCode;
  let agentSubtitle = getAgentErrorSubtitle(error.Error);
  let agentTitle = getAgentErrorTitle(error.Error);
  let titles = textConstants.GENERIC_ERROR_TITLES;

  switch (error.Error) {
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      if (paymentError.value.type === Payment.Rewards)
        titles = textConstants.REDEMPTION_FAILED_TITLES;
      break;
    case PaymentErrorEnum.ErrorCode:
      agentSubtitle = getAgentErrorSubtitle(code);
      agentTitle = getAgentErrorTitle(code);

      switch (code) {
        case PaymentErrorEnum.UserCardNotFound:
          titles = textConstants.USER_CARD_ERROR_TITLES;
          break;
        case PaymentErrorEnum.FraudAutoReject:
        case PaymentErrorEnum.LikelyFraud:
          titles = textConstants.FRAUD_TITLES;
          break;
        case HotelBookErrorType.CheckInMinimumAgeNotMet:
          titles = textConstants.CHECK_IN_AGE_NOT_MET;
          break;
        case HotelBookErrorType.PaymentError:
          titles = textConstants.PAYMENT_ERROR_TITLES;
          break;
        case HotelBookErrorType.LackOfInventory:
          trackEvent({
            eventName: NO_AVAILABILITY_HOTELS,
            properties: {},
          });
          titles = textConstants.LACK_OF_INVENTORY_TITLES;
          break;
        case HotelBookErrorType.RateNotAvailable:
          trackEvent({
            eventName: NO_AVAILABILITY_HOTELS,
            properties: {},
          });
          titles = textConstants.RATE_NOT_AVAILABLE_TITLES;
          break;

        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
      }
      break;
    case PurchaseErrorEnum.ProductError:
      const { errorEnum } = (error as ProductError).value.value;
      agentSubtitle = getAgentErrorSubtitle(errorEnum);
      agentTitle = getAgentErrorTitle(errorEnum);
      switch (errorEnum) {
        case PaymentErrorEnum.UserCardNotFound:
          titles = textConstants.USER_CARD_ERROR_TITLES;
          break;
        case PaymentErrorEnum.FraudAutoReject:
        case PaymentErrorEnum.LikelyFraud:
          titles = textConstants.FRAUD_TITLES;
          break;
        case HotelBookErrorType.CheckInMinimumAgeNotMet:
          titles = textConstants.CHECK_IN_AGE_NOT_MET;
          break;
        case HotelBookErrorType.PaymentError:
          titles = textConstants.PAYMENT_ERROR_TITLES;
          break;
        case HotelBookErrorType.LackOfInventory:
          trackEvent({
            eventName: NO_AVAILABILITY_HOTELS,
            properties: {},
          });
          titles = textConstants.LACK_OF_INVENTORY_TITLES;
          break;
        case HotelBookErrorType.RateNotAvailable:
          trackEvent({
            eventName: NO_AVAILABILITY_HOTELS,
            properties: {},
          });
          titles = textConstants.RATE_NOT_AVAILABLE_TITLES;
          break;

        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
      }
      break;
    default:
  }

  return { ...titles, agentSubtitle, agentTitle };
};

export const getScheduleBookCallState = (state: IStoreState) =>
  state.premierCollectionBook.scheduleBookCallState;

export const priceQuoteParamsSelector = createSelector(
  getFromDate,
  getUntilDate,
  getPremierCollectionShopChosenRoomInfo,
  getPremierCollectionShopChosenProduct,
  getUserSelectedTravelerId,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getPremierCollectionShopSelectedAvailability,
  getAgentEmail,
  selectedCfarIdSelector,
  (
    fromDate,
    untilDate,
    roomInfo,
    product,
    personId,
    emailAddress,
    phoneNumber,
    lodging,
    agentEmail,
    selectedCfarId
  ): HotelPriceQuoteScheduleRequestV2 | null => {
    if (!product || !personId || !lodging) {
      return null;
    } else {
      const hotelQuoteRequest: HotelQuoteScheduleRequest = {
        token: "00000000-0000-0000-0000-000000000000",
        quoteRequest: product?.opaqueQuoteRequest,
        personId,
        emailAddress: emailAddress || "",
        phoneNumber: phoneNumber || "",
        dates:
          fromDate && untilDate
            ? {
                from: dayjs(fromDate).format("YYYY-MM-DD"),
                until: dayjs(untilDate).format("YYYY-MM-DD"),
              }
            : undefined,
        id: lodging.lodging.id,
        name: lodging.lodging.name,
        city: lodging.lodging.city,
        country: lodging.lodging.country,
        starRating: lodging.lodging.starRating,
        providerName: lodging.price?.providerName,
        bedTypes: roomInfo?.roomInfo.beds,
        lodgingCollection: lodging.lodgingCollection
          ? lodging.lodgingCollection
          : LodgingCollectionEnum.NoCollection,
      };
      if (agentEmail) {
        hotelQuoteRequest.delegatedTo = agentEmail;
      }
      return {
        hotelQuoteRequest,
        hotelAncillaryIds: {
          cfarId:
            selectedCfarId && selectedCfarId?.value !== DO_NOT_APPLY_OPTION_KEY
              ? selectedCfarId
              : undefined,
        },
      };
    }
  }
);

export const bookingInProgressSelector = createSelector(
  getScheduleBookCallState,
  getConfirmationDetailsCallState,
  (scheduleBookCallState, confirmationCallState) => {
    return (
      scheduleBookCallState === CallState.InProcess ||
      confirmationCallState === CallState.InProcess
    );
  }
);

export const hasNoUserPassengersSelector = createSelector(
  getUserPassengers,
  getUserPassengerCallState,
  (userPassengers, userPassengerCallState) => {
    return (
      userPassengers.length === 0 &&
      userPassengerCallState === CallState.Success
    );
  }
);

// PAYMENT

export const getRewardsPaymentAccountReferenceId = (state: IStoreState) =>
  state.premierCollectionBook.rewardsAccountReferenceId;

export const getRewardsPaymentAccount = createSelector(
  getRewardsPaymentAccountReferenceId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getRewardsPaymentInFiatCurrency = (state: IStoreState) =>
  state.premierCollectionBook.rewardsPaymentInFiatCurrency;

export const getRewardsPaymentInRewardsCurrency = (state: IStoreState) =>
  state.premierCollectionBook.rewardsPaymentTotal;

export const getRewardsRemainingAfterPurchase = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInFiatCurrency,
  (paymentAccount, rewardsPaymentAmount) => {
    if (!paymentAccount) return "";

    const {
      rewardsCashEquivalent: { value, currencyCode, currencySymbol },
    } = paymentAccount;

    const remainder = value - (rewardsPaymentAmount?.value || 0);

    return getTotalPriceText({
      price: {
        currencyCode,
        currencySymbol,
        value: remainder,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getTravelWalletOfferToApplyAmount = createSelector(
  getTravelWalletItemsToApply,
  getProductPricingEstimateWithAllFees,
  getPriceQuotePricing,
  (travelWalletItemsToApply) => {
    if (travelWalletItemsToApply.offerToApply) {
      return travelWalletItemsToApply.offerToApply.amount.amount * -1;
    } else {
      return 0;
    }
  }
);

export const getTravelWalletCreditToApplyAmount = createSelector(
  getTravelWalletItemsToApply,
  getPriceQuotePricing,
  getPremierCollectionShopChosenProduct,
  getTravelWalletOfferToApplyAmount,
  (
    travelWalletItemsToApply,
    priceQuotePricing,
    product,
    offerAppliedAmount
  ) => {
    const totalFiatValue =
      priceQuotePricing?.payNowTotal.fiat.value ||
      product?.total.fiat.value ||
      0;
    const totalFiatValueWithOfferApplied: number =
      totalFiatValue - offerAppliedAmount;
    if (
      travelWalletItemsToApply.creditToApply &&
      totalFiatValueWithOfferApplied > 0
    ) {
      const maxApplicableCredit =
        travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;
      let amount =
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
          ? maxApplicableCredit
          : travelWalletItemsToApply.creditToApply.amount.amount;
      if (totalFiatValueWithOfferApplied < amount * -1) {
        if (
          maxApplicableCredit &&
          maxApplicableCredit <
            travelWalletItemsToApply.creditToApply.amount.amount &&
          travelWalletItemsToApply.creditToApply.amount.amount * -1 <
            totalFiatValueWithOfferApplied
        ) {
          return travelWalletItemsToApply.creditToApply.amount.amount * -1;
        }
        return totalFiatValueWithOfferApplied;
      }
      if (
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
      ) {
        return travelWalletItemsToApply.creditToApply.amount.amount * -1;
      }
      return amount * -1;
    } else {
      return 0;
    }
  }
);

export const getMaxApplicableTravelWalletCreditOnlyAmount = createSelector(
  getCredit,
  getPremierCollectionShopChosenProduct,
  getPriceQuotePricing,
  (credit, product, priceQuotePricing) => {
    if (priceQuotePricing && credit) {
      if (
        priceQuotePricing.payNowTotal.fiat.value <
        credit.amount.amount * -1
      ) {
        return priceQuotePricing.payNowTotal.fiat.value;
      }
      return credit.amount.amount * -1;
    } else if (product && credit) {
      let totalFiatValue = product.total.fiat.value + credit?.amount.amount;
      if (totalFiatValue < 0) {
        return product.total.fiat.value * -1;
      }

      return credit.amount.amount * -1;
    } else {
      return 0;
    }
  }
);

export const getMaxApplicableTravelWalletCreditAmount = createSelector(
  getCredit,
  getPremierCollectionShopChosenProduct,
  getPriceQuotePricing,
  getTravelWalletOfferToApplyAmount,
  (credit, product, priceQuotePricing, offerAppliedAmount) => {
    if (priceQuotePricing && credit) {
      if (
        priceQuotePricing.payNowTotal.fiat.value - offerAppliedAmount <
        credit.amount.amount * -1
      ) {
        return (
          (priceQuotePricing.payNowTotal.fiat.value - offerAppliedAmount) * -1
        );
      }
      return credit.amount.amount;
    } else if (product && credit) {
      let totalFiatValue = product.total.fiat.value + credit?.amount.amount;
      if (totalFiatValue < 0) {
        return (product.total.fiat.value - offerAppliedAmount) * -1;
      }

      return credit.amount.amount;
    } else {
      return 0;
    }
  }
);

export const getTotalToPay = createSelector(
  getPricingEstimateTotal,
  getTravelWalletItemsToApply,
  getPriceQuotePricing,
  (estimate, travelWalletItemsToApply, priceQuotePricing): Prices | null => {
    let total = priceQuotePricing ? priceQuotePricing.payNowTotal : estimate;

    const rewardsTotal: Record<string, RewardsPrice> = {};
    if (
      total &&
      (travelWalletItemsToApply.offerToApply ||
        travelWalletItemsToApply.creditToApply)
    ) {
      let walletItemsTotal: number =
        travelWalletItemsToApply.offerToApply?.amount.amount || 0;

      if (travelWalletItemsToApply.creditToApply) {
        const maxApplicableCredit =
          travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;
        if (
          maxApplicableCredit &&
          maxApplicableCredit >
            travelWalletItemsToApply.creditToApply.amount.amount
        ) {
          walletItemsTotal += maxApplicableCredit;
        } else {
          walletItemsTotal +=
            travelWalletItemsToApply.creditToApply.amount.amount;
        }
      }
      Object.keys(total.rewards).forEach((accountId) => {
        if (total) {
          let rewardTotalValue = total.rewards[accountId].value;
          rewardTotalValue =
            total.rewards[accountId].currency === "Cash"
              ? rewardTotalValue + walletItemsTotal
              : rewardTotalValue + walletItemsTotal * 100;
          rewardsTotal[accountId] = {
            currency: total.rewards[accountId].currency,
            currencyDescription: total.rewards[accountId].currencyDescription,
            value: rewardTotalValue > 0 ? rewardTotalValue : 0,
          };
        }
      });
      return {
        fiat: {
          currencyCode: total.fiat.currencyCode,
          currencySymbol: total.fiat.currencySymbol,
          value:
            total.fiat.value + walletItemsTotal > 0
              ? total.fiat.value + walletItemsTotal
              : 0,
        },
        rewards: rewardsTotal,
      };
    }
    return priceQuotePricing ? priceQuotePricing.payNowTotal : estimate;
  }
);

export const getIsTravelWalletCreditPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      !travelWalletItemsToApply.offerToApply &&
      travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsTravelWalletOfferPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      travelWalletItemsToApply.offerToApply &&
      !travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsStackedTravelWalletPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      travelWalletItemsToApply.offerToApply &&
      travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsTravelWalletPaymentOnly = createSelector(
  getIsTravelWalletCreditPaymentOnly,
  getIsTravelWalletOfferPaymentOnly,
  getIsStackedTravelWalletPaymentOnly,
  (
    isTravelWalletCreditPaymentOnly,
    isTravelWalletOfferPaymentOnly,
    isStackedTravelWalletPaymentOnly
  ) => {
    if (
      isTravelWalletCreditPaymentOnly ||
      isTravelWalletOfferPaymentOnly ||
      isStackedTravelWalletPaymentOnly
    ) {
      return true;
    }
    return false;
  }
);

export const getIsCreditCardPaymentRequired = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  getIsTravelWalletPaymentOnly,
  (total, rewardsPaymentAmount, isTravelWalletPaymentOnly) => {
    if (isTravelWalletPaymentOnly) return false;

    if (!total) return true;
    if (!rewardsPaymentAmount) return true;

    return total.fiat.value > rewardsPaymentAmount.value;
  }
);

export const getTotalCreditCardPaymentRequired = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (estimate, rewardsPaymentAmount) => {
    if (!estimate) return "";

    if (!rewardsPaymentAmount) {
      return getTotalPriceText({
        price: estimate.fiat,
        priceFormatter: twoDecimalFormatter,
      });
    }

    const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
    const total = remainder <= 0 ? 0 : remainder;

    return getTotalPriceText({
      price: {
        currencyCode: estimate.fiat.currencyCode,
        currencySymbol: estimate.fiat.currencySymbol,
        value: total,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getTotalCreditCardPaymentRequiredNumber = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (estimate, rewardsPaymentAmount) => {
    if (!estimate) return null;

    if (!rewardsPaymentAmount) {
      return estimate.fiat.value;
    }

    const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
    const total =
      remainder <= 0 ? 0 : Math.round((remainder + Number.EPSILON) * 100) / 100;
    return total;
  }
);

export const getTotalCreditCardPaymentRequiredInFiatPrice = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (totalToPay, rewardsPaymentAmount): FiatPrice | undefined => {
    if (!totalToPay) return undefined;

    if (!rewardsPaymentAmount) return totalToPay.fiat;

    const remainder = totalToPay.fiat.value - rewardsPaymentAmount.value;
    const total = remainder <= 0 ? 0 : remainder;

    return {
      currencyCode: totalToPay.fiat.currencyCode,
      currencySymbol: totalToPay.fiat.currencySymbol,
      value: total,
    };
  }
);

export const getPaymentMethodRewardsAccountId = (state: IStoreState) =>
  state.premierCollectionBook.paymentMethodRewardsAccountId;

export const getAddedAncillaryLineItems = createSelector(
  getAddedAncillariesPricing,
  (addedAncillariesPricing): IHotelPriceLineItem[] => {
    // CFAR - The Prices should be taken from priceQuote once BE work is done
    return addedAncillariesPricing
      .map((pricing) => {
        switch (pricing.kind) {
          case AncillaryKindEnum.Cfar:
            return {
              icon: "check-shield-blue",
              title: textConstants.CANCEL_FOR_ANY_REASON,
              value: getTotalPriceText({
                price: pricing.premium.fiat,
                priceFormatter: twoDecimalFormatter,
              }),
            };
          default:
            return [];
        }
      })
      .flat();
  }
);

export const getPremierCollectionPricingLineItems = createSelector(
  getSearchedNightCount,
  getPremierCollectionShopChosenProduct,
  getPriceQuote,
  getRewardsPaymentAccount,
  getPaymentMethodRewardsAccountId,
  getRewardsAccounts,
  getPriceQuotePricing,
  getAddedAncillaryLineItems,
  getAddedAncillariesPricing,
  (
    nights,
    product,
    priceQuote,
    rewardsPaymentAccount,
    paymentMethodRewardsAccountId,
    rewardsAccounts,
    priceQuotePricing,
    addedAncillaryLineItems,
    addedAncillaryPricing
  ): IHotelPriceLineItem[][] => {
    if (priceQuote && priceQuotePricing && product) {
      const rewardsAccountId = rewardsPaymentAccount
        ? rewardsPaymentAccount.accountReferenceId
        : paymentMethodRewardsAccountId
        ? paymentMethodRewardsAccountId
        : Object.keys(product.total.rewards)[0];
      const total = priceQuotePricing.payNowTotal;

      const includeRewards =
        rewardsAccounts.find(
          (account) => account.accountReferenceId === rewardsAccountId
        )?.allowRewardsRedemption ?? true;

      return [
        [
          {
            title: textConstants.ROOM_TOTAL(nights, 1),
            value: getTotalPriceText({
              price: priceQuotePricing.subtotal.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
          } as IHotelPriceLineItem,
          {
            title: textConstants.TAXES_AND_FEES,
            value: getTotalPriceText({
              price: priceQuotePricing.taxes.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
          } as IHotelPriceLineItem,
          ...addedAncillaryLineItems,
        ],
        [
          {
            title: textConstants.DUE_TODAY,

            value: getTotalPriceText({
              price: {
                currencyCode: total.fiat.currencyCode,
                currencySymbol: total.fiat.currencySymbol,
                value: total.fiat.value,
              },
              priceFormatter: twoDecimalFormatter,
            }),
            rewardsValue: includeRewards
              ? getRewardsString(total.rewards[rewardsAccountId])
              : undefined,
            boldLabel: true,
            type: "due-today",
          } as IHotelPriceLineItem,
          ...(priceQuotePricing.feeBreakdown.total
            ? [
                {
                  title: textConstants.ADDITIONAL_FEES,
                  value: getTotalPriceText({
                    price: priceQuotePricing.feeBreakdown.total,
                    priceFormatter: twoDecimalFormatter,
                  }),
                  type: "additional-fees",
                } as IHotelPriceLineItem,
              ]
            : []),
          ...(priceQuotePricing.feeBreakdown.total
            ? [
                {
                  title: textConstants.TOTAL,
                  value: getTotalPriceText({
                    price: priceQuotePricing.tripTotal.fiat,
                    priceFormatter: twoDecimalFormatter,
                  }),
                  boldLabel: true,
                  type: "total",
                } as IHotelPriceLineItem,
              ]
            : []),
        ],
      ];
    } else if (product) {
      const rewardsAccount = rewardsPaymentAccount
        ? rewardsPaymentAccount.accountReferenceId
        : Object.keys(product.total.rewards)[0];

      const includeRewards =
        rewardsAccounts.find(
          (account) => account.accountReferenceId === rewardsAccount
        )?.allowRewardsRedemption ?? true;

      const dueTodayTotal = getSubtotalWithAncillariesLegacy(
        product.total,
        addedAncillaryPricing
      );

      const total = getSubtotalWithAncillariesLegacy(
        product.tripTotal,
        addedAncillaryPricing
      );

      return [
        [
          {
            title: textConstants.ROOM_TOTAL(nights, 1),
            value: getTotalPriceText({
              price: product.sellRate.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
          } as IHotelPriceLineItem,
          {
            title: textConstants.TAXES_AND_FEES,
            value: getTotalPriceText({
              price: {
                value: Object.keys(product.taxBreakdown).reduce(
                  (sum, key) => product.taxBreakdown[key].fiat.value + sum,
                  0
                ),
                currencyCode: product.sellRate.fiat.currencyCode,
                currencySymbol: product.sellRate.fiat.currencySymbol,
              },
              priceFormatter: twoDecimalFormatter,
            }),
          } as IHotelPriceLineItem,
          ...addedAncillaryLineItems,
        ],
        [
          {
            title: textConstants.DUE_TODAY,
            value: getTotalPriceText({
              price: dueTodayTotal.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
            rewardsValue: includeRewards
              ? getRewardsString(dueTodayTotal.rewards[rewardsAccount])
              : undefined,
            boldLabel: true,
            type: "due-today",
          } as IHotelPriceLineItem,
          ...(product.feeBreakdown.total
            ? [
                {
                  title: textConstants.ADDITIONAL_FEES,
                  value: getTotalPriceText({
                    price: product.feeBreakdown.total,
                    priceFormatter: twoDecimalFormatter,
                  }),
                  type: "additional-fees",
                } as IHotelPriceLineItem,
              ]
            : []),
          ...(product.feeBreakdown.total
            ? [
                {
                  title: textConstants.TOTAL,
                  value: getTotalPriceText({
                    price: total.fiat,
                    priceFormatter: twoDecimalFormatter,
                  }),
                  boldLabel: true,
                  type: "total",
                } as IHotelPriceLineItem,
              ]
            : []),
        ],
      ];
    } else {
      return [];
    }
  }
);

export const getPremierCollectionTripTotalInPrices = createSelector(
  getPriceQuote,
  getPremierCollectionShopChosenProduct,
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  getPriceQuotePricing,
  (
    priceQuote,
    chosenProduct,
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue,
    priceQuotePricing
  ): { fiat: FiatPrice; rewards?: RewardsPrice } | null => {
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;

    const includeRewards = activeRewardsAccount?.allowRewardsRedemption ?? true;

    if (priceQuote && priceQuotePricing && activeRewardsAccount) {
      return {
        fiat: priceQuotePricing.tripTotal.fiat,
        rewards: includeRewards
          ? priceQuotePricing.tripTotal.rewards[
              activeRewardsAccount.accountReferenceId
            ]
          : undefined,
      };
    } else if (chosenProduct && activeRewardsAccount) {
      return {
        fiat: chosenProduct.tripTotal.fiat,
        rewards: includeRewards
          ? chosenProduct.tripTotal.rewards[
              activeRewardsAccount.accountReferenceId
            ]
          : undefined,
      };
    }
    return null;
  }
);

export const getPremierCollectionRewardsAndTotalLineItems = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getTotalCreditCardPaymentRequired,
  getSelectedPaymentMethodId,
  getPaymentMethods,
  getTravelWalletItemsToApply,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  (
    rewardsPaymentAccount,
    rewardsPayment,
    rewardsInFiat,
    totalAmountDueOnCredit,
    paymentMethodId,
    paymentMethods,
    travelWalletItemsToApply,
    offerAmountToApply,
    creditAmountToApply
  ) => {
    const rewardsLineItems = [];

    if (
      rewardsPaymentAccount &&
      (rewardsPaymentAccount.allowRewardsRedemption ?? true)
    ) {
      const fiatPrice = rewardsInFiat ?? {
        currencyCode: rewardsPaymentAccount?.rewardsCashEquivalent.currencyCode,
        currencySymbol:
          rewardsPaymentAccount?.rewardsCashEquivalent.currencySymbol,
        value: 0,
      };

      const priceText = getTotalPriceText({
        price: {
          ...fiatPrice,
          value: fiatPrice.value,
        },
        priceFormatter: twoDecimalFormatter,
      });

      const rewardsText = rewardsPayment
        ? `- ${getRewardsString(rewardsPayment)}`
        : "";
      rewardsLineItems.push({
        title: `${rewardsPaymentAccount?.productDisplayName} ${textConstants.REWARDS}`,
        value: `- ${priceText}`,
        rewardsValue: rewardsText,
      });
    }

    const paymentMethod = paymentMethods.find((p) => p.id === paymentMethodId);
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      const travelWalletItems = [];
      if (travelWalletItemsToApply.offerToApply && offerAmountToApply) {
        travelWalletItems.push({
          title: textConstants.TRAVEL_OFFER_APPLIED,
          value: getTotalPriceText({
            price: {
              value: offerAmountToApply * -1,
              currencyCode:
                travelWalletItemsToApply.offerToApply?.amount.currency,
              currencySymbol: CurrencyFormatters.getSymbol(
                travelWalletItemsToApply.offerToApply?.amount.currency
              ),
            },
            priceFormatter: twoDecimalFormatter,
          }),
          isTravelOffer: true,
          isTravelCredit: false,
          icon: "offer-tag",
        });
      }
      if (travelWalletItemsToApply.creditToApply && creditAmountToApply) {
        const breakdownHasStatementCredit =
          !!travelWalletItemsToApply.creditToApply.breakdown?.some(
            (detail) =>
              detail.CreditDetail === "Statement" &&
              Math.abs(detail.usableAmount.amount) > 0
          );
        travelWalletItems.push({
          title: breakdownHasStatementCredit
            ? textConstants.TOTAL_TRAVEL_CREDITS_APPLIED
            : textConstants.TRAVEL_CREDITS_APPLIED,
          value: getTotalPriceText({
            price: {
              value: creditAmountToApply * -1,
              currencyCode:
                travelWalletItemsToApply.creditToApply?.amount.currency,
              currencySymbol: CurrencyFormatters.getSymbol(
                travelWalletItemsToApply.creditToApply?.amount.currency
              ),
            },
            priceFormatter: twoDecimalFormatter,
          }),
          isTravelOffer: false,
          isTravelCredit: true,
          icon: "piggy-bank-icon",
          className: "wallet-item",
          travelCreditBreakdown: breakdownHasStatementCredit
            ? getCheckoutCreditBreakdown(
                travelWalletItemsToApply.creditToApply.breakdown || [],
                creditAmountToApply,
                travelWalletItemsToApply.creditToApply.amount.currency
              )
            : [],
        });
      }

      return [
        ...travelWalletItems,
        ...rewardsLineItems,
        {
          title: paymentMethod
            ? `Ending in ${paymentMethod.last4}:`
            : textConstants.AMOUNT_DUE,
          value: totalAmountDueOnCredit,
          icon: IconName.Payment,
        },
      ];
    }

    return [
      ...rewardsLineItems,
      {
        title: paymentMethod
          ? `Ending in ${paymentMethod.last4}:`
          : textConstants.AMOUNT_DUE,
        value: totalAmountDueOnCredit,
        icon: IconName.Payment,
      },
    ];
  }
);

export enum Progress {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export const getCombinedBookingSteps = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getIsCreditCardPaymentRequired,
  getAllowRewardsWithPolicy,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired,
    canRedeemRewards
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      } else if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      } else {
        return Progress.COMPLETED;
      }
    };
    return [
      {
        name: textConstants.TRAVELER_INFORMATION,
        status:
          !!traveler &&
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.IN_PROGRESS,
      },
      {
        name:
          canRedeemRewards || isCaponeTenant(config.TENANT)
            ? textConstants.REWARDS_AND_PAYMENT
            : textConstants.CORP_REWARDS_AND_PAYMENT_SHORTENED,
        status: paymentCompleted(),
      },
    ];
  }
);

export const getBookingProgress = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getIsCreditCardPaymentRequired,
  getAllowRewardsWithPolicy,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired,
    canRedeemRewards
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      } else if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      } else {
        return Progress.COMPLETED;
      }
    };
    return [
      {
        name: textConstants.ADD_TRAVELERS,
        status: !!traveler ? Progress.COMPLETED : Progress.IN_PROGRESS,
      },
      {
        name: textConstants.CONTACT,
        status:
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.NOT_STARTED,
      },
      {
        name:
          canRedeemRewards || isCaponeTenant(config.TENANT)
            ? textConstants.REWARDS_AND_PAYMENT
            : textConstants.CORP_REWARDS_AND_PAYMENT_SHORTENED,
        status: paymentCompleted(),
      },
    ];
  }
);

export const getBookingInProgress = createSelector(
  getSchedulePriceQuoteCallState,
  getPollPriceQuoteCallState,
  getScheduleBookCallState,
  getConfirmationDetailsCallState,
  (scheduleQuote, pollPriceQuote, scheduleBook, confirmDetails) => {
    return (
      scheduleQuote === CallState.InProcess ||
      pollPriceQuote === CallState.InProcess ||
      scheduleBook === CallState.InProcess ||
      confirmDetails === CallState.InProcess
    );
  }
);

export const getIsBookingValid = createSelector(
  getPriceDifference,
  getPriceDifferenceAcknowledged,
  getIsCreditCardPaymentRequired,
  getSelectedPaymentMethodId,
  getUserSelectedTravelerId,
  getBookingInProgress,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getIsTravelWalletPaymentOnly,
  (
    priceDifference,
    priceDifferenceAcknowledged,
    creditCardRequired,
    selectedPaymentMethodId,
    userSelectedTravelerId,
    bookingInProgress,
    email,
    phone,
    isTravelWalletPaymentOnly
  ) => {
    const paymentUnsettled =
      creditCardRequired &&
      !selectedPaymentMethodId &&
      !isTravelWalletPaymentOnly;

    if (
      (priceDifference.hasDifference && !priceDifferenceAcknowledged) ||
      paymentUnsettled ||
      !userSelectedTravelerId ||
      bookingInProgress ||
      !email ||
      !phone
    ) {
      return false;
    } else {
      return true;
    }
  }
);

export const getEarn = (state: IStoreState) =>
  state.premierCollectionBook.productEarnValue;

const getPaymentRequestType = createSelector(
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  (
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    selectedPaymentMethodId
  ) => {
    if (selectedPaymentMethodId && !rewardsPayment) {
      return PaymentSplitRequestEnum.PaymentCardRequest;
    } else if (
      rewardsAccountId &&
      rewardsPayment &&
      rewardsFiatPayment &&
      creditCardPayment?.value === 0
    ) {
      return PaymentSplitRequestEnum.PaymentRewardsRequest;
    } else if (rewardsPayment && rewardsAccountId && rewardsFiatPayment) {
      return PaymentSplitRequestEnum.PaymentCardRewardsRequest;
    } else {
      return null;
    }
  }
);

export const getCardPaymentRewardsAccount = createSelector(
  getPaymentMethodRewardsAccountId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getEarnedString = createSelector(
  getEarn,
  getCardPaymentRewardsAccount,
  (earn, account) => {
    if (
      !earn ||
      (isCorpTenant(config.TENANT) &&
        account?.customerAccountRole !== "Primary")
    )
      return "";
    return textConstants.EARNED_STRING(
      earn,
      account?.rewardsBalance.currencyDescription
    );
  }
);

export const getPaymentRequest = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getSession,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    session
  ): PaymentSplitRequest | null => {
    let amount: PaymentType | null = null;
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: UserCardPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            currency: creditCardPayment!.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment!.value),
            PaymentAmount: PaymentAmountEnum.FiatAmount,
          },
          Payment: TypeOfPaymentEnum.UserCard,
        };
        amount = userCardPayment;
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitPayment: SplitPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            fiatAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
              PaymentAmount: PaymentAmountEnum.FiatAmount,
            },
            rewardsAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
              PaymentAmount: PaymentAmountEnum.RewardsAmount,
            },
            PaymentAmount: PaymentAmountEnum.SplitAmount,
          },
          Payment: TypeOfPaymentEnum.Split,
        };
        amount = splitPayment;
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: RewardsPaymentType = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
            PaymentAmount: PaymentAmountEnum.RewardsAmount,
          },
          Payment: TypeOfPaymentEnum.Rewards,
        };
        amount = rewardsPaymentType;
        break;
      default:
        return null;
    }

    if (amount && session) {
      const request = {
        token: session,
        payment: amount,
        ancillaries: [],
      };
      return request;
    } else {
      return null;
    }
  }
);

export const getOpaquePayments = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getTravelWalletItemsToApply,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    travelWalletItemsToApply,
    offerToApplyAmount,
    creditToApplyAmount
  ): PaymentOpaqueValue[] | null => {
    let amount: PaymentOpaqueValue[] = [];
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      if (travelWalletItemsToApply.offerToApply) {
        const offer: PaymentOpaqueValue = {
          type: Payment.TravelWalletOffer,
          value: {
            offerId: travelWalletItemsToApply.offerToApply.id,
            description:
              travelWalletItemsToApply.offerToApply?.descriptions[0] || "",
            paymentAmount: {
              fiatValue: {
                amount: offerToApplyAmount,
                currency: travelWalletItemsToApply.offerToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletOffer,
          },
        };
        amount.push(offer);
      }
      if (travelWalletItemsToApply.creditToApply && creditToApplyAmount > 0) {
        const credit: PaymentOpaqueValue = {
          type: Payment.TravelWalletCredit,
          value: {
            offerId: travelWalletItemsToApply.creditToApply.id,
            description: "TravelWalletCredit", // creditToApply doesn't have a description
            paymentAmount: {
              fiatValue: {
                amount: creditToApplyAmount,
                currency:
                  travelWalletItemsToApply.creditToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletCredit,
          },
        };
        amount.push(credit);
      }
    }
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: PaymentOpaqueValue = {
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          },
        };
        amount.push(userCardPayment);
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitUserCardPayment: PaymentOpaqueValue = {
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          },
        };

        const splitRewardsPayment: PaymentOpaqueValue = {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          },
        };

        amount.push(splitUserCardPayment, splitRewardsPayment);
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: PaymentOpaqueValue = {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          },
        };
        amount.push(rewardsPaymentType);
        break;
      default:
        break;
    }

    if (amount) {
      return amount;
    } else {
      return null;
    }
  }
);

export const getPaymentRequestV2 = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getSession,
  getTravelWalletItemsToApply,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    session,
    travelWalletItemsToApply,
    offerToApplyAmount,
    creditToApplyAmount
  ): PaymentSplitRequestV2 | null => {
    let amount: PaymentV2[] = [];
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      if (travelWalletItemsToApply.offerToApply) {
        const offer: TravelWalletOfferPayment = {
          offerId: travelWalletItemsToApply.offerToApply.id,
          description:
            travelWalletItemsToApply.offerToApply?.descriptions[0] || "",
          paymentAmount: {
            fiatValue: {
              amount: offerToApplyAmount,
              currency: travelWalletItemsToApply.offerToApply.amount.currency,
            },
          },
          PaymentV2: PaymentV2Enum.TravelWalletOffer,
        };
        amount.push(offer);
      }
      if (travelWalletItemsToApply.creditToApply && creditToApplyAmount > 0) {
        const credit: TravelWalletCreditPayment = {
          offerId: travelWalletItemsToApply.creditToApply.id,
          description: "TravelWalletCredit", // creditToApply doesn't have a description
          paymentAmount: {
            fiatValue: {
              amount: roundToTwoDecimals(creditToApplyAmount),
              currency: travelWalletItemsToApply.creditToApply.amount.currency,
            },
          },
          PaymentV2: PaymentV2Enum.TravelWalletCredit,
        };
        amount.push(credit);
      }
    }
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: UserCard = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            currency: creditCardPayment!.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment!.value),
          },
          PaymentV2: PaymentV2Enum.UserCard,
        };
        amount.push(userCardPayment);
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitUserCardPayment: UserCard = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            currency: creditCardPayment!.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment!.value),
          },
          PaymentV2: PaymentV2Enum.UserCard,
        };

        const splitRewardsPayment: Rewards = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
          },
          PaymentV2: PaymentV2Enum.Rewards,
        };

        amount.push(splitUserCardPayment, splitRewardsPayment);
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: Rewards = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
          },
          PaymentV2: PaymentV2Enum.Rewards,
        };
        amount.push(rewardsPaymentType);
        break;
      default:
        break;
    }

    if (amount && session) {
      const request = {
        token: session,
        payments: amount,
        ancillaries: [],
      };
      return request;
    } else {
      return null;
    }
  }
);

export const getCardPaymentAccount = createSelector(
  getRewardsAccounts,
  getPaymentRequest,
  (rewardsAccounts, paymentRequest: PaymentSplitRequest | null) => {
    switch (paymentRequest?.payment.Payment) {
      case TypeOfPaymentEnum.UserCard:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as UserCardPaymentType).accountReferenceId
        );
      case TypeOfPaymentEnum.Split:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as SplitPaymentType).accountReferenceId
        );
      default:
        return null;
    }
  }
);

export enum CheckoutSteps {
  ADD_TRAVELERS = 0,
  CONTACT = 1,
  REWARDS_AND_PAYMENT = 2,
}

export const isTravelerStepComplete = createSelector(
  getBookingProgress,
  (checkoutProgress) => {
    return (
      checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status ===
      Progress.COMPLETED
    );
  }
);

export const isContactStepComplete = createSelector(
  getBookingProgress,
  (checkoutProgress) => {
    return (
      checkoutProgress[CheckoutSteps.CONTACT].status === Progress.COMPLETED
    );
  }
);

export const areAllStepsCompletedInCheckout = createSelector(
  getBookingProgress,
  getIsTravelWalletPaymentOnly,
  (checkoutProgress, isTravelWalletPaymentOnly) => {
    return (
      checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status ===
        Progress.COMPLETED &&
      checkoutProgress[CheckoutSteps.CONTACT].status === Progress.COMPLETED &&
      (!isTravelWalletPaymentOnly
        ? checkoutProgress[CheckoutSteps.REWARDS_AND_PAYMENT].status ===
          Progress.COMPLETED
        : true)
    );
  }
);

export const verifyPaymentMethodResultSelector = (state: IStoreState) =>
  state.premierCollectionBook.verifyPaymentMethodResult;

export const getModalType = createSelector(
  getPriceQuoteErrors,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsError,
  getPriceDifference,
  verifyPaymentMethodResultSelector,
  (
    priceQuoteErrors,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsErrors,
    priceDifference,
    verifyPaymentMethodResult
  ): string => {
    if (priceDifference.hasDifference && priceDifference.amount) {
      return "pc_price_difference";
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      return "payment_verification_failed";
    }

    if (confirmationDetailsErrors.length > 0) {
      return "confirmation_details_failed";
    }

    if (scheduleBookError) {
      return "schedule_book_failed";
    }

    if (schedulePriceQuoteError) {
      return "schedule_price_quote_failed";
    }

    if (priceQuoteErrors.length > 0) {
      return "poll_price_quote_failed";
    }

    return "";
  }
);

export const getErrorTitles = createSelector(
  getHasError,
  getPriceQuoteErrors,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsError,
  getPriceDifference,
  verifyPaymentMethodResultSelector,
  getTravelWalletItemsToApply,
  getModalType,
  (
    hasError,
    priceQuoteErrors,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsErrors,
    priceDifference,
    verifyPaymentMethodResult,
    travelWalletItemsToApply,
    modalType
  ): ErrorTitles => {
    if (!hasError) return { title: "", subtitle: "", primaryButtonText: "" };

    const properties: ModalAlertProperties = {
      type: modalType,
      category: ModalCategoryType.TROUBLE,
      screen: ModalScreens.HOTELS_CHECKOUT,
      primary_button: "",
      secondary_button: "",
      modal_subtitle: "",
      modal_title: "",
      agent_title: "",
      agent_subtitle: "",
      step: "",
      funnel: "premier_collection",
    };

    const callTrackEvent = (properties: ModalAlertProperties) => {
      trackEvent({
        eventName: MODAL_ALERT,
        properties,
      });
    };

    if (priceDifference.hasDifference && priceDifference.amount) {
      const primaryButtonText = textConstants.CONTINUE;
      properties.primary_button = primaryButtonText;
      callTrackEvent(properties);

      const offerAmount =
        travelWalletItemsToApply.offerToApply?.amount.amount || 0;
      const creditsAmount =
        travelWalletItemsToApply.creditToApply?.amount.amount || 0;

      const walletItemCoverageChanged =
        (travelWalletItemsToApply.offerToApply ||
          travelWalletItemsToApply.creditToApply) &&
        Math.abs(offerAmount + creditsAmount) >=
          priceDifference.predictedTotal &&
        Math.abs(offerAmount + creditsAmount) < priceDifference.priceQuoteTotal;

      if (walletItemCoverageChanged) {
        return {
          title: travelWalletItemsToApply.creditToApply
            ? textConstants.PRICE_INCREASE_CREDITS_COVERAGE_TITLE
            : textConstants.PRICE_INCREASE_OFFER_COVERAGE_TITLE,
          subtitle: textConstants.PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE,
          primaryButtonText: primaryButtonText,
          secondaryButtonText: textConstants.CHOOSE_ANOTHER_HOTEL,
          icon: priceDifference.isIncrease
            ? textConstants.PRICE_INCREASE_ICON
            : textConstants.PRICE_DECREASE_ICON,
        };
      }

      return {
        title: textConstants.getPriceDifferenceTitle(
          priceDifference.isIncrease,
          priceDifference.amount
        ),
        subtitle: priceDifference.isIncrease
          ? textConstants.PRICE_INCREASE_SUBTITLE
          : textConstants.PRICE_DECREASE_SUBTITLE,
        primaryButtonText: primaryButtonText,
        icon: priceDifference.isIncrease
          ? textConstants.PRICE_INCREASE_ICON
          : textConstants.PRICE_DECREASE_ICON,
      };
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      const returnProps = textConstants.PAYMENT_METHOD_ERROR_TITLES(
        verifyPaymentMethodResult
      );
      properties.primary_button = returnProps.primaryButtonText || "";
      callTrackEvent(properties);
      return returnProps;
    }

    if (confirmationDetailsErrors.length > 0) {
      const returnProps = getErrorMessage(confirmationDetailsErrors[0]);
      properties.primary_button = returnProps.primaryButtonText || "";
      callTrackEvent(properties);
      return returnProps;
    }

    if (scheduleBookError) {
      const returnProps = textConstants.GENERIC_ERROR_TITLES;
      properties.primary_button = returnProps.primaryButtonText || "";
      callTrackEvent(properties);
      return returnProps;
    }

    if (schedulePriceQuoteError) {
      const returnProps = textConstants.GENERIC_ERROR_TITLES;
      properties.primary_button = returnProps.primaryButtonText || "";
      callTrackEvent(properties);
      return returnProps;
    }

    if (priceQuoteErrors.length > 0) {
      const returnProps = getErrorMessage(priceQuoteErrors[0]);
      properties.primary_button = returnProps.primaryButtonText || "";
      callTrackEvent(properties);
      return returnProps;
    }

    const returnProps = textConstants.GENERIC_ERROR_TITLES;
    properties.primary_button = returnProps.primaryButtonText || "";
    callTrackEvent(properties);
    return returnProps;
  }
);

export const getReviewPremierCollectionDetailsCheckoutProperties =
  createSelector(
    getPremierCollectionShopSelectedAvailability,
    getPremierCollectionShopChosenProduct,
    getFromDate,
    getUntilDate,
    getNightCount,
    getViewedPremierCollectionDetailsProperties,
    getPriceQuotePricing,
    getPaymentMethods,
    getAdultsCount,
    getChildrenCount,
    getPetsCount,
    (
      hotelAvail,
      product,
      fromDate,
      toDate,
      nights,
      viewedPremierCollectionDetailsProperties,
      priceQuotePricing,
      paymentMethods,
      adultsCount,
      childrenCount,
      petsCount,
    ): ITrackingProperties<
      Omit<ReviewDetailsHotelCheckoutProperties, "pc_shown" | "lc_shown">
    > => {
      const productTaxes = product
        ? Object.keys(product.taxBreakdown).reduce(
            (sum, key) => product.taxBreakdown[key].fiat.value + sum,
            0
          )
        : 0;
      return {
        properties: {
          lodging_name: hotelAvail?.lodging.name || "",
          chain: "",
          brand: "",
          length_of_stay: nights || 0,
          sell_price_excluding_taxes_and_fees_usd:
            priceQuotePricing?.subtotal.fiat.value ||
            product?.tripTotal.fiat.value ||
            0,
          owed_now_excluding_taxes_and_fees:
            priceQuotePricing?.subtotal.fiat.value ||
            product?.tripTotal.fiat.value ||
            0,
          owed_now_taxes_and_fees:
            priceQuotePricing?.taxes.fiat.value || productTaxes || 0,
          owed_at_checkin_taxes_and_fees:
            priceQuotePricing?.taxes?.fiat.value || productTaxes || 0,
          pay_later_excluding_taxes_and_fees:
            priceQuotePricing?.taxes?.fiat.value || productTaxes || 0,
          pay_later_taxes_and_fees:
            priceQuotePricing?.taxes?.fiat.value || productTaxes || 0,
          pay_later_fees:
            priceQuotePricing?.feeBreakdown.total?.value ||
            product?.feeBreakdown.total?.value ||
            0,
          total_discount: 0,
          refundability:
            product?.cancellationPolicy.CancellationPolicy ===
            CancellationPolicyEnum.NonRefundable
              ? "non_refundable"
              : "free_cancellation",
          supply_revenue_usd:
            priceQuotePricing?.subtotal.fiat.value ||
            product?.tripTotal.fiat.value ||
            0,
          check_in_date: fromDate ? dayjs(fromDate).format("MM/DD/YYYY") : "",
          check_out_date: toDate ? dayjs(toDate).format("MM/DD/YYYY") : "",
          supplier: "",
          provider_type: "direct", // // TODO find out where this comes from -> "direct" | "retail" | "wholesale",
          adults_count: adultsCount,
          children_count: childrenCount,
          pets_searched: petsCount,
          adr: "",
          ...viewedPremierCollectionDetailsProperties.properties,
          ...hotelAvail?.trackingPropertiesV2?.properties,
          card_on_file: paymentMethods.length > 0,
        },
        encryptedProperties: [
          ...viewedPremierCollectionDetailsProperties.encryptedProperties,
          hotelAvail?.trackingPropertiesV2?.encryptedProperties ?? "",
        ],
      };
    }
  );

export const getSelectedPaymentCardType = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  getSelectedPaymentMethodId,
  (accounts, paymentMethods, id): string => {
    const paymentMethod = paymentMethods.find((p) => p.id === id);
    if (
      accounts[0]?.customerAccountRole === CustomerAccountRole.NonFinancialUser
    ) {
      return paymentMethod?.cardType ?? "";
    }
    return (
      accounts.find(
        (a) =>
          a.lastFour === paymentMethod?.last4 ||
          a.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4 || "")
      )?.productDisplayName || ""
    );
  }
);

export const getPriceQuotePremierCollectionProperties = createSelector(
  getReviewPremierCollectionDetailsCheckoutProperties,
  getSession,
  getSelectedLodgingIndex,
  getTravelWalletItemsToApply,
  (
    reviewPremierCollectionDetailsProperties,
    session,
    selectedLodgingIndex,
    travelWalletItemsToApply
  ): ITrackingProperties<PriceQuotePremierCollectionProperties> => {
    return {
      properties: {
        ...reviewPremierCollectionDetailsProperties.properties,
        success: false,
        booking_session_id: session?.value || "",
        lodging_row_index:
          selectedLodgingIndex != null ? selectedLodgingIndex : undefined,
        ...travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
          ?.properties,
        ...travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
          ?.properties,
      },
      encryptedProperties: [
        ...reviewPremierCollectionDetailsProperties.encryptedProperties,
        travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
        travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getIsPaymentMethodVCN = createSelector(
  getPaymentMethodRewardsAccountId,
  getPaymentMethod,
  getRewardsAccounts,
  (paymentRewardsAccountId, paymentMethod, rewardsAccounts) => {
    if (!paymentRewardsAccountId || !paymentMethod || !rewardsAccounts)
      return false;

    const account = rewardsAccounts.find(
      (account) => account.accountReferenceId === paymentRewardsAccountId
    );

    return !!(
      account?.lastFourVirtualCardNumbers &&
      paymentMethod?.last4 &&
      account?.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4)
    );
  }
);

export const getDatelessSearchTrackingProperty = createSelector(
  getIsFromHotelDatelessSearch,
  (
    isFromHotelDatelessSearch
  ): ITrackingProperties<DatelessSearchProperties> => {
    return {
      properties: {
        is_dateless: isFromHotelDatelessSearch ?? false,
      },
      encryptedProperties: [],
    };
  }
);

export const getCompleteBuyPremierCollectionProperties = createSelector(
  getReviewPremierCollectionDetailsCheckoutProperties,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentAccount,
  getSelectedPaymentCardType,
  getConfirmationDetails,
  getSession,
  getSelectedLodgingIndex,
  getOffers,
  getTravelWalletCreditToApplyAmount,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletItemsToApply,
  getIsPaymentMethodVCN,
  getDatelessSearchTrackingProperty,
  (
    reviewProperties,
    cardPayment,
    rewardPayment,
    rewardCurrencyPayment,
    account,
    cardType,
    reservation,
    session,
    selectedLodgingIndex,
    offers,
    creditToApplyAmount,
    offerToApplyAmount,
    travelWalletItemsToApply,
    isPaymentMethodVCN,
    datelessSearchProperties
  ): ITrackingProperties<
    | CompleteBuyPremierCollectionProperties
    | CompleteBuyLifestyleCollectionProperties
  > => {
    return {
      properties: {
        ...reviewProperties.properties,
        success: false,
        total_rewards_amount_usd: rewardPayment?.value || 0,
        total_card_amount_usd: cardPayment?.value || 0,
        card_product_used: cardType,
        rewards_product_used: account?.productDisplayName || "",
        rewards_currency: rewardCurrencyPayment?.currency || "",
        agent_booking_fee_amount_usd: 0,
        booking_session_id: session?.value || "",
        reservation_id: reservation?.reservationId || "",
        lodging_row_index:
          selectedLodgingIndex != null ? selectedLodgingIndex : undefined,
        ...travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
          ?.properties,
        ...travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
          ?.properties,
        offer_amount_redeemed_usd:
          !!travelWalletItemsToApply.offerToApply && !!offerToApplyAmount
            ? Math.abs(offerToApplyAmount)
            : 0,
        offer_count: offers?.length,
        offer_used: !!travelWalletItemsToApply.offerToApply,
        rooms_booked: reviewProperties.properties.rooms_searched,
        credit_redeemed:
          !!travelWalletItemsToApply.creditToApply?.amount.amount,
        credit_amt_redeemed:
          !!travelWalletItemsToApply.creditToApply && !!creditToApplyAmount
            ? Math.abs(creditToApplyAmount)
            : 0,
        is_vcn: isPaymentMethodVCN,
        ...datelessSearchProperties.properties,
      },
      encryptedProperties: [
        ...reviewProperties.encryptedProperties,
        travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
        travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
        ...datelessSearchProperties.encryptedProperties,
      ],
    };
  }
);

export const rewardsAccountMinimumRequirementStateSelector = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccountMinimumRequirementState => {
    return getRewardsAccountMinimumRequirementState(rewardsAccounts);
  }
);

export const getOffersRequest = createSelector(
  getPremierCollectionShopSelectedAvailability,
  getSearchedNightCount,
  getFromDate,
  getUntilDate,
  getPremierCollectionShopChosenProduct,
  getPriceQuote,
  (
    selectedLodging,
    nightCount,
    fromDate,
    untilDate,
    chosenProduct,
    priceQuote
  ) => {
    if (
      selectedLodging &&
      nightCount &&
      fromDate &&
      untilDate &&
      chosenProduct
    ) {
      return {
        products: [
          {
            AvailableProduct: AvailableProductEnum.Lodging,
            id: selectedLodging?.lodging.id,
            merchant: MerchantOfRecord.Hopper,
            lengthOfStay: nightCount,
            stayPeriod: {
              from: dayjs(fromDate).format("YYYY-MM-DD"),
              until: dayjs(untilDate).format("YYYY-MM-DD"),
            },
            isPreferred: selectedLodging?.isPreferred,
            isFreeCancel: selectedLodging?.isFreeCancel,
            productPricing: {
              productClass: ProductClassEnum.Economy,
              currency:
                selectedLodging.price?.totalPrice.fiat.currencyCode ?? "USD",
              basePrice:
                chosenProduct.totalDiscountAware.priceWithNoDiscounts.fiat
                  .value,
              grossBookValue:
                chosenProduct.totalDiscountAware.priceWithUnmanagedDiscounts
                  .fiat.value,
              ancillaryTotal: 0, // hardcoded bc Tysons does this https://github.com/hopper-org/Tysons/blob/1167df0bdaa8ae2a5255ea0203438deacff071ae/server/src/main/scala/com/hopper/tysons/service/hotels/availability/converter/ImpFastAvailabilityResponseConverter.scala#L552
              preDiscountTotal:
                chosenProduct.totalDiscountAware.priceWithUnmanagedDiscounts
                  .fiat.value,
              preDiscountGrandTotal:
                priceQuote?.pricing.tripTotal.fiat.value ??
                chosenProduct.tripTotal.fiat.value,
              sellPrice:
                priceQuote?.pricing.tripTotal.fiat.value ??
                chosenProduct.tripTotal.fiat.value,
            },
          },
        ],
      };
    } else {
      return null;
    }
  }
);

export const getRewardAccountsWithPaymentAccounts = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  (rewardsAccounts, paymentMethods) => {
    const accountsWithSavedPayment = paymentMethods.reduce(
      (savedPayments, currentAcc) => {
        const matchingRewardsAcct = rewardsAccounts.find(
          (rewards) =>
            rewards.lastFour === currentAcc.last4 ||
            rewards.lastFourVirtualCardNumbers?.includes(currentAcc.last4 || "")
        );

        if (matchingRewardsAcct) {
          savedPayments.push(matchingRewardsAcct);
        }
        const isTestCard =
          window.__mclean_env__.ENV !== "production" &&
          currentAcc.last4 &&
          TEST_CARD_LAST_FOURS.includes(currentAcc.last4);
        if (isTestCard) {
          savedPayments.push(rewardsAccounts[0]);
        }

        return savedPayments;
      },
      [] as RewardsAccount[]
    );
    return accountsWithSavedPayment;
  }
);

export const getEarnValuesByRewardAcctId = (state: IStoreState) =>
  state.premierCollectionBook.earnValuesByRewardAcctId;
